.layoutH3{
    font-size: 32px ;
    line-height: 38px ;

}

.l-layoutP{
    font-size: 18px;
    line-height: 135%;
    color: #D1D1D1;
}

@media only screen and (max-width: 900px) {
    .layoutH3{
        font-size: 25px;
        line-height: 27px;
        text-align: center;  
    }

    .l-layoutP{
        font-size: 14px;
        line-height: 135%;
        text-align: center;
    }
}
/*
@media only screen and (min-width: 768px) {
    .layoutH3{
        font-size: 25px;
        line-height: 28px;
    }

    .l-layoutP{
        font-size: 14px;
        line-height: 130%;
        padding-top: 5px;
    }
}
*/


