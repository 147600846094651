.logo{
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.2em;
    color: #E6E6E6;
    cursor: pointer !important;

}

.nav-button{
    font-size: 15px;
    line-height: 24px;
    color: #191919;
}

.nav-button-mobile{
    font-size: 15px;
    line-height: 24px;
    background-color: #0063AE;
    color: #191919;
    margin-top: 10px !important;
    padding: 10px 0px;
    
}

@media only screen and (max-width: 600px) {
    .logo{
        font-size: 20px;
    }

    .nav-button{
        background-color: #0063AE !important;
    }


}

@media only screen and (min-width: 768px) {
   
     .logo{
        font-size: 20px;
    }

 }
