.jumbotron{
    background: #EDD5AB;  
    padding: 20px 20px !important;
    border-radius: 10px;
}

.mail-input{
    border-bottom: 1px solid #8C8C8C !important;
    outline: none !important;
}

.rightH1{
    font-size: 32px;
    color: #191919;
    padding-top: 9px;
}
.p-2{
    padding: 10px !important;
    border-radius: 20px;
}

.submit{
    background: #0063AE;
    border-radius: 8px;
    font-weight: 500;
    font-size: 17px;
    width: 100%;
    height: 48px; 
}



@media only screen and (max-width: 900px) {
    .rightH1{
        font-size: 22px;
    }


    .submit{
        width: 100%;
        height: 48px;
        background: #0063AE;
        border-radius: 8px;
    }

    .jumbotron{
        border-radius: 10px;
    }
}